import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'users_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="users_outline_56"><g fill="none" fill-rule="evenodd"><path d="M0 0h56v56H0z" /><path d="M39.5 31c5.609 0 10.5 2.699 10.5 8 0 2.625-1.833 4-4 4h-8.5a1.5 1.5 0 010-3H46c.667 0 1-.25 1-1 0-3.164-3.328-5-7.5-5-.71 0-1.482.07-2.226.208a1.5 1.5 0 11-.548-2.95A15.385 15.385 0 0139.5 31zm-19-2c7.356 0 13.5 2.836 13.5 9 0 3.078-1.922 5-4.5 5h-18C8.814 43 7 41.59 7 38.5c0-6.545 6.065-9.5 13.5-9.5zm0 3C14.514 32 10 34.2 10 38.5c0 1.244.33 1.5 1.5 1.5h18c.922 0 1.5-.578 1.5-2 0-3.874-4.606-6-10.5-6zM39 15a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm-18.5-4a7.5 7.5 0 110 15 7.5 7.5 0 010-15zM39 18a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm-18.5-4a4.5 4.5 0 100 9 4.5 4.5 0 000-9z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56UsersOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56UsersOutline: FC<Icon56UsersOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56UsersOutline as any).mountIcon = mountIcon;

export default Icon56UsersOutline;
