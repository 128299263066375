import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'writebar_send_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="writebar_send_48"><path d="M24 7c9.389 0 17 7.611 17 17s-7.611 17-17 17S7 33.389 7 24 14.611 7 24 7zm0 10h-.018a.99.99 0 00-.071.004L24 17a1.017 1.017 0 00-.52.146 1.045 1.045 0 00-.097.067l-.008.007-.082.073-5.5 5.5a1 1 0 001.414 1.414L23 20.414V30.5a1 1 0 102 0V20.414l3.793 3.793a1 1 0 001.414-1.414l-5.5-5.5-.082-.073-.008-.007.09.08a1.012 1.012 0 00-.559-.282.906.906 0 00-.129-.01L24 17z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48WritebarSendProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48WritebarSend: FC<Icon48WritebarSendProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48WritebarSend as any).mountIcon = mountIcon;

export default Icon48WritebarSend;
