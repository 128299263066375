import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'gesture_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="gesture_outline_56"><path clip-rule="evenodd" d="M28.512 10.168a2.776 2.776 0 015.458.994L32.515 21.33a1.498 1.498 0 001.295 1.698c4.374.855 7.69 4.747 7.69 9.384C41.5 39.883 35.443 46 27.994 46H26.96c-7.167-.045-12.907-6.123-12.432-13.252a6.692 6.692 0 016.677-6.247h.993a2.636 2.636 0 01.757 5.16l-1.345.403a1.5 1.5 0 10.862 2.874l1.345-.404a5.635 5.635 0 00-1.62-11.033h-.992c-1.173 0-2.3.21-3.344.593l-1.347-12.137a2.658 2.658 0 015.237-.86l1.755 8.085a1.5 1.5 0 002.958.19zm-3.49 1.871l.561-2.52a5.776 5.776 0 017.04-4.345 5.768 5.768 0 014.317 6.411l-1.262 8.822c5.113 1.586 8.822 6.387 8.822 12.005C44.5 41.521 37.12 49 27.994 49h-1.012c-8.88-.034-16.041-7.552-15.448-16.453a9.672 9.672 0 013.492-6.822l-1.49-13.438C13.162 8.933 15.784 6 19.154 6c2.66 0 4.96 1.857 5.524 4.46z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56GestureOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56GestureOutline: FC<Icon56GestureOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56GestureOutline as any).mountIcon = mountIcon;

export default Icon56GestureOutline;
