import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'writebar_done_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="writebar_done_48"><path d="M24 7c9.389 0 17 7.611 17 17s-7.611 17-17 17S7 33.389 7 24 14.611 7 24 7zm6.957 12.126a1 1 0 00-1.414 0L21.5 27.17l-3.46-3.46a1 1 0 10-1.414 1.415l4.167 4.166a1 1 0 001.414 0l8.75-8.75a1 1 0 000-1.414z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48WritebarDoneProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48WritebarDone: FC<Icon48WritebarDoneProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48WritebarDone as any).mountIcon = mountIcon;

export default Icon48WritebarDone;
